import Swal from "sweetalert2";
import { config } from "../config.js";
import { sortBy, uniqBy } from "lodash";
import EventSource from "eventsource";
import dayjs from "dayjs";
let BASE_URL = "";
config.loadConfig().then((configResult) => {
  BASE_URL = configResult.api;
});

export default {
  namespaced: true,
  state: {
    isCanceled: false,
    chatHistory: {
      loading: false,
      pagination: {
        total: 0,
        page: 0,
        itemsPerPage: 0,
        lastPage: null,
        data: [],
      },
    },
    chatMessages: {
      loading: false,
      pagination: {
        nextCursor: null,
        limit: null,
        data: [],
      },
    },
    createChatLoading: false,
    selectedChat: null,
    messageGenerating: false,
    sendMessageLoading: false,
    currentChatRunId: null,
    cancelChatRunLoading: false,
  },
  mutations: {
    SET_IS_CANCELED: (state, data) => {
      state.isCanceled = data;
    },
    EMPTY_CURRENT_MESSAGE: (state) => {
      state.currentMessage = "";
    },
    SET_CHAT_HISTORY_LOADING: (state, data) => {
      state.chatHistory.loading = data;
    },
    SET_CHAT_HISTORY_PAGINATION: (state, data) => {
      state.chatHistory.pagination = data;
    },
    SET_CHAT_MESSAGES_LOADING: (state, data) => {
      state.chatMessages.loading = data;
    },
    SET_CHAT_MESSAGES_PAGINATION: (state, data) => {
      state.chatMessages.pagination = data;
    },
    SET_CREATE_CHAT_LOADING: (state, data) => {
      state.createChatLoading = data;
    },
    SET_SELECTED_CHAT: (state, data) => {
      state.selectedChat = data;
    },
    STORE_CHAT_MESSAGE: (state, data) => {
      const messageIndex = state.chatMessages.pagination.data.findIndex(
        (message) => message.id == data.id
      );
      if (messageIndex == -1) {
        state.chatMessages.pagination.data = [
          ...state.chatMessages.pagination.data,
          data,
        ];
      }
    },
    UPDATE_CHAT_MESSAGE: (state, data) => {
      const messageIndex = state.chatMessages.pagination.data.findIndex(
        (message) => message.id == data.id
      );
      if (messageIndex != -1) {
        if (data.replace) {
          state.chatMessages.pagination.data[messageIndex] = {
            ...state.chatMessages.pagination.data[messageIndex],
            content: data.content,
            attachments: data.attachments,
          };
        } else {
          let currentContent =
            state.chatMessages.pagination.data[messageIndex].content;
          state.chatMessages.pagination.data[messageIndex] = {
            ...state.chatMessages.pagination.data[messageIndex],
            content: currentContent + data.content,
            attachments: data.attachments,
          };
        }
        state.chatMessages.pagination.data = [
          ...state.chatMessages.pagination.data,
        ];
      }
    },
    SET_MESSAGE_GENERATING: (state, data) => {
      state.messageGenerating = data;
    },
    SET_SEND_MESSAGE_LOADING: (state, data) => {
      state.sendMessageLoading = data;
    },
    SET_CHAT_TITLE: (state, { chatId, title }) => {
      const chatIndex = state.chatHistory.pagination.data.findIndex(
        (chat) => chat.id == chatId
      );
      if (chatIndex != -1) {
        state.chatHistory.pagination.data[chatIndex].title = title;
      }
      state.chatHistory.pagination.data = [
        ...state.chatHistory.pagination.data,
      ];
    },
    RESET_CHAT_MESSAGES: (state) => {
      state.chatMessages = {
        loading: false,
        pagination: {
          nextCursor: null,
          limit: null,
          data: [],
        },
      };
    },
    SET_RUN_ID: (state, data) => {
      state.currentChatRunId = data;
    },
    SET_CANCEL_RUN_LOADING: (state, data) => {
      state.cancelChatRunLoading = data;
    },
  },
  actions: {
    getChats: function ({ commit, state }, payload) {
      const vm = this;
      commit("SET_CHAT_HISTORY_LOADING", true);
      let url = `bot-chat?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.title) {
          url = `${url}&title=${payload.title}`;
        }
      }
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            if (payload.limit) {
              if (payload.page == 1) {
                commit("SET_CHAT_HISTORY_PAGINATION", {
                  total: res.data.meta.total,
                  page: res.data.meta.current_page,
                  itemsPerPage: res.data.meta.per_page,
                  lastPage: res.data.meta.last_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_CHAT_HISTORY_PAGINATION", {
                  total: res.data.meta.total,
                  page: res.data.meta.current_page,
                  itemsPerPage: res.data.meta.per_page,
                  lastPage: res.data.meta.last_page,
                  data: uniqBy(
                    [...state.chatHistory.pagination.data, ...res.data.data],
                    "id"
                  ),
                });
              }
            } else {
              commit("SET_CHAT_HISTORY_PAGINATION", {
                total: 0,
                page: 0,
                itemsPerPage: 0,
                lastPage: null,
                data: res.data.data,
              });
            }
            commit("SET_CHAT_HISTORY_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_CHAT_HISTORY_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createChat: function ({ commit }) {
      const vm = this;
      commit("SET_CREATE_CHAT_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.post("bot-chat").then(
          (res) => {
            commit("SET_CREATE_CHAT_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_CREATE_CHAT_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getChatMessages: function ({ commit, state }, payload) {
      commit("SET_CHAT_MESSAGES_LOADING", true);
      let url = `bot-chat/${payload.chatId}/messages?`;
      if (payload) {
        if (payload.limit) {
          url = `${url}limit=${payload.limit}`;
        }
        if (payload.cursor) {
          url = `${url}&cursor=${payload.cursor}`;
        }
      }
      let vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            if (payload.limit) {
              if (payload.cursor) {
                commit("SET_CHAT_MESSAGES_PAGINATION", {
                  nextCursor: res.data.meta.next_cursor,
                  limit: res.data.meta.per_page,
                  data: [
                    ...sortBy([...res.data.data], ["created_at"]),
                    ...state.chatMessages.pagination.data,
                  ],
                });
              } else {
                commit("SET_CHAT_MESSAGES_PAGINATION", {
                  nextCursor: res.data.meta.next_cursor,
                  limit: res.data.meta.per_page,
                  data: sortBy(res.data.data, ["created_at"]),
                });
              }
            } else {
              commit("SET_CHAT_MESSAGES_PAGINATION", {
                nextCursor: null,
                limit: null,
                data: res.data.data,
              });
            }
            commit("SET_CHAT_MESSAGES_LOADING", false);
            resolve(res);
          },
          (err) => {
            commit("SET_CHAT_MESSAGES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    sendChatMessage: function ({ commit }, payload) {
      commit("SET_SEND_MESSAGE_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`bot-chat/${payload.chatId}/messages`, payload.data)
          .then(
            (res) => {
              commit("SET_SEND_MESSAGE_LOADING", false);
              resolve(res);
            },
            (err) => {
              commit("SET_SEND_MESSAGE_LOADING", false);
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    uploadFile: function ({ commit }, payload) {
      commit("SET_SEND_MESSAGE_LOADING", true);
      const vm = this;
      let attachmentIndex = payload.message.attachments.findIndex(
        (attachment) => attachment.id == payload.attachmentId
      );
      if (attachmentIndex == -1) {
        return;
      }
      const fileName = `${payload.file.name}.${payload.file.extension}`;
      const formData = new FormData();
      formData.append("file", payload.file.blob, fileName);
      return new Promise((resolve) => {
        return vm.$http
          .post(`bot-chat/file`, formData, {
            onUploadProgress: (progress) => {
              const percentage = Math.round(
                (progress.loaded / progress.total) * 100
              );
              payload.message.attachments[attachmentIndex].progress =
                percentage;
            },
            Headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(
            (res) => {
              delete payload.message.attachments[attachmentIndex].progress;
              payload.message.attachments[attachmentIndex].url =
                res.data.data.url;
              resolve(res.data.data);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    establishSSEConnection: function ({ commit, state }, chatId) {
      commit("SET_MESSAGE_GENERATING", true);
      const token = localStorage.getItem("ARSS_TOKEN");
      return new Promise((resolve) => {
        const evtSource = new EventSource(
          `${BASE_URL}bot-chat/${chatId}/sse-messages`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        evtSource.addEventListener("thread.message.created", function (event) {
          const data = JSON.parse(event.data) || {};
          const message = data.message || null;
          if (message && message.role == "assistant") {
            commit("STORE_CHAT_MESSAGE", message);
          }
        });
        evtSource.addEventListener("thread.message.delta", function (event) {
          const data = JSON.parse(event.data) || {};
          const message = data.message || null;
          if (state.isCanceled) {
            evtSource.close();
            commit("SET_MESSAGE_GENERATING", false);
          }
          if (message && message.role == "assistant") {
            commit("UPDATE_CHAT_MESSAGE", message);
          }
        });
        evtSource.addEventListener(
          "thread.message.completed",
          function (event) {
            const data = JSON.parse(event.data) || {};
            const message = data.message || null;
            message.replace = true;
            if (message && message.role == "assistant") {
              commit("UPDATE_CHAT_MESSAGE", message);
            }
          }
        );
        evtSource.addEventListener("thread.run.created", function (event) {
          const data = JSON.parse(event.data) || {};
          const runId = data.run_id || null;
          if (runId) {
            commit("SET_RUN_ID", runId);
          }
        });
        evtSource.addEventListener("thread.run.step.created", function (event) {
          const data = JSON.parse(event.data) || {};
          const step = data.step || null;
          if (step) {
            const stepId = step.id || null;
            const stepType = step.type || null;
            const threadId = step.threadId || null;
            if (stepType == "tool_calls") {
              let message = {
                id: stepId,
                thread_id: threadId,
                content: "⏳ Analyzing...",
                role: "assistant",
                system: true,
                attachments: [],
                created_at: dayjs(),
              };
              commit("STORE_CHAT_MESSAGE", message);
            }
          }
        });

        evtSource.addEventListener(
          "thread.run.step.completed",
          function (event) {
            const data = JSON.parse(event.data) || {};
            const step = data.step || null;
            if (step) {
              const stepId = step.id || null;
              const stepType = step.type || null;
              const threadId = step.threadId || null;
              if (stepType == "tool_calls") {
                let message = {
                  id: stepId,
                  thread_id: threadId,
                  content: "✅ Analysis completed",
                  role: "assistant",
                  system: true,
                  replace: true,
                  attachments: [],
                  created_at: dayjs(),
                };
                commit("UPDATE_CHAT_MESSAGE", message);
              }
            }
          }
        );
        evtSource.addEventListener("thread.run.step.failed", function (event) {
          const data = JSON.parse(event.data) || {};
          const step = data.step || null;
          if (step) {
            const stepId = step.id || null;
            const stepType = step.type || null;
            const threadId = step.threadId || null;
            if (stepType == "tool_calls") {
              let message = {
                id: stepId,
                thread_id: threadId,
                content: "❌ Error occurred while analyzing",
                role: "assistant",
                system: true,
                replace: true,
                attachments: [],
                created_at: dayjs(),
              };
              commit("UPDATE_CHAT_MESSAGE", message);
            }
          }
        });
        evtSource.addEventListener("thread.run.failed", function (event) {
          const data = JSON.parse(event.data) || {};
          const status = data.status || null;
          if (
            status &&
            ["failed", "cancelled", "cancelling", "expired"].includes(status)
          ) {
            evtSource.close();
            commit("SET_MESSAGE_GENERATING", false);
            commit("SET_RUN_ID", null);
            resolve(true);
          }
        });

        evtSource.addEventListener("thread.run.completed", function () {
          evtSource.close();
          commit("SET_MESSAGE_GENERATING", false);
          commit("SET_RUN_ID", null);
          resolve(true);
        });

        evtSource.onerror = function (event) {
          evtSource.close();
          commit("SET_MESSAGE_GENERATING", false);
          Swal.fire({
            title: "Error",
            text: event?.message || "Something went wrong...",
            icon: "error",
          });
          resolve(false);
        };
      });
    },
    generateChatTitle: function ({ commit }, chatId) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.post(`bot-chat/${chatId}/generate-title`).then(
          (res) => {
            commit("SET_CHAT_TITLE", {
              chatId: chatId,
              title: res.data.data.title,
            });
            resolve(res);
          },
          () => {
            resolve(false);
          }
        );
      });
    },
    cancelChatRun: function ({ commit }, payload) {
      commit("SET_CANCEL_RUN_LOADING", true);
      commit("SET_IS_CANCELED", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`bot-chat/${payload.chatId}/cancel`, payload.data)
          .then(
            (res) => {
              commit("SET_CANCEL_RUN_LOADING", false);
              commit("SET_RUN_ID", null);
              commit("SET_IS_CANCELED", false);
              resolve(res);
            },
            () => {
              commit("SET_CANCEL_RUN_LOADING", false);
              resolve(false);
            }
          );
      });
    },
    setSelectedChat: function ({ commit }, payload) {
      commit("SET_SELECTED_CHAT", payload);
    },
    storeChatMessage: function ({ commit, state }, payload) {
      commit("STORE_CHAT_MESSAGE", payload);
      return state.chatMessages.pagination.data.at(-1);
    },
    resetChatMessages: function ({ commit }) {
      commit("RESET_CHAT_MESSAGES");
    },
    stopGenerating: function ({ commit }) {
      commit("SET_IS_CANCELED", true);
    },
  },
};

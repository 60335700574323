import dayjs from "dayjs";
import router from "../router";

let dummyData = [
  {
    Id: "25",
    Description: "Testing description data 2",
    DateTime: "2020-10-07",
    AlertType: {
      Name: "Tag Alerts",
      Code: "TagAlerts",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "1",
    Description: "Testing description data 2",
    DateTime: "2020-10-07",
    AlertType: {
      Name: "Expired Caregiver",
      Code: "ExpiredCaregiver",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "10",
    Description: "Testing description data 2",
    DateTime: "2020-10-07",
    AlertType: {
      Name: "Expired Client Care Plan",
      Code: "ExpiredClientCarePlan",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "11",
    Description: "Testing description data 2",
    DateTime: "2020-10-07",
    AlertType: {
      Name: "Follow Up Call",
      Code: "FollowUpCall",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "12",
    Description: "Testing description data 2",
    DateTime: "2020-10-07",
    AlertType: {
      Name: "Marketing Meetings",
      Code: "MarketingMeetings",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "13",
    Description: "Testing description data 2",
    DateTime: "2020-10-07",
    AlertType: {
      Name: "Telephone Messages",
      Code: "TelephoneMessages",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "3",
    Description: "Testing descriptions data",
    DateTime: "2020-09-07",
    AlertType: {
      Name: "Tag Alerts",
      Code: "TagAlerts",
    },
    ClientsList: [
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [{ Id: "2", Name: "testing 2" }],
  },

  {
    Id: "4",
    Description: "Testing description data",
    DateTime: "2020-07-07",
    AlertType: {
      Name: "Tag Alerts",
      Code: "TagAlerts",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },

  {
    Id: "21",
    Description: "Testing description data 1",
    DateTime: "2020-10-10",
    AlertType: {
      Name: "Telephone Messages",
      Code: "TelephoneMessages",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
    ],
    CaregiversList: [{ Id: "1", Name: "testing 1" }],
  },
  {
    Id: "5",
    Description: "Testing description data",
    DateTime: "2020-10-17",
    AlertType: {
      Name: "Tag Alerts",
      Code: "TagAlerts",
    },
    ClientsList: [
      {
        Id: "1",
        Name: "James",
      },
      {
        Id: "2",
        Name: "Nathalie ",
      },
    ],
    CaregiversList: [
      { Id: "1", Name: "testing 1" },

      { Id: "2", Name: "testing 2" },
    ],
  },
];

export default {
  namespaced: true,
  state: {
    data: [],
    alert: null,
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    create: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      this.$http
        .post("users", data.alert)
        .then((res) => {
          let alert = res.data.data;

          context.commit("SET_STATE", { key: "isSaving", value: false });
          router.push({
            name: "alerts.basic",
            params: {
              id: alert.id,
            },
          });
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isSaving", value: false });
          //eslint-disable-next-line
          console.log(err);
        });
    },
    update: function (context, data) {
      if (data.alert) {
        context.commit("SET_STATE", { key: "isSaving", value: true });
        this.$http
          .put("users/" + data.alert, data)
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", { key: "isSaving", value: false });
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              alert(err);
            }
          });
      }
    },

    search: function (context, searchObject) {
      // if (data.alert) {
      //     context.commit('SET_STATE', { key: 'isSaving', value: true });
      //     this.$http
      //         .put('users/' + data.alert, data)
      //         .then((res) => {
      //             if (res) {
      //                 context.commit('SET_STATE', { key: 'isSaving', value: false });
      //             }
      //         })
      //         .catch((err) => {
      //             alert(err);
      //         });
      // }

      context.commit("SET_STATE", { key: "isLoading", value: true });

      var searchText = searchObject[0];
      var searchDateFilter = searchObject[1];
      var alertTypeFilter = searchObject[2];
      var matchedData = dummyData;
      if (searchText != "" && searchText != null) {
        matchedData = dummyData.filter(function (dummyData) {
          return dummyData["Description"]
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });
      }

      if (searchDateFilter != "" && searchDateFilter != null) {
        matchedData = matchedData.filter(function (item) {
          return (
            dayjs(item["DateTime"]).isAfter(dayjs(searchDateFilter), "day") ||
            dayjs(item["DateTime"]).isSame(dayjs(searchDateFilter), "day")
          );
        });
      }

      if (alertTypeFilter != "" && alertTypeFilter != null) {
        matchedData = matchedData.filter(function (dummyData) {
          return alertTypeFilter.includes(dummyData["AlertType"]["Code"]);
        });
      }

      context.commit("SET_STATE", {
        key: "data",
        value: matchedData,
      });
      context.commit("SET_STATE", { key: "isLoading", value: false });
    },

    get: function (context, id) {
      context.commit("SET_STATE", { key: "isLoading", value: true });
      if (typeof id == "undefined") {
        //TODO , enable it once API is ready, reaturn dummy data now!

        context.commit("SET_STATE", {
          key: "data",
          value: dummyData,
        });
        context.commit("SET_STATE", { key: "isLoading", value: false });
      } else {
        let foundData = dummyData.filter(function (dummyData) {
          return dummyData["Id"] == id;
        })[0];
        context.commit("SET_STATE", {
          key: "alert",
          value: foundData,
        });
        context.commit("SET_STATE", { key: "isLoading", value: false });
      }
    },
  },
};
